:root {
	--space-0: 0;
	--space-1: 2px;
	--space-2: 4px;
	--space-3: 6px;
	--space-4: 8px;
	--space-5: 10px;
	--space-6: 12px;
	--space-7: 14px;
	--space-8: 16px;
	--space-9: 18px;
	--space-10: 20px;
	
}

@media(max-width: 1300px) {
	:root {
		--space-0: 0;
		--space-1: 1px;
		--space-2: 2px;
		--space-3: 4px;
		--space-4: 6px;
		--space-5: 8px;
		--space-6: 10px;
		--space-7: 12px;
		--space-8: 14px;
		--space-9: 16px;
		--space-10: 18px;
	}
}

@media(max-width: 1100px) {
	:root {
		--space-0: 0;
		--space-1: .5px;
		--space-2: 1px;
		--space-3: 2px;
		--space-4: 4px;
		--space-5: 6px;
		--space-6: 8px;
		--space-7: 10px;
		--space-8: 12px;
		--space-9: 14px;
		--space-10: 16px;
	}
}

@media(min-width: 1500px) {
	:root {
		--space-0: 0;
		--space-1: 4px;
		--space-2: 6px;
		--space-3: 8px;
		--space-4: 10px;
		--space-5: 12px;
		--space-6: 14px;
		--space-7: 16px;
		--space-8: 18px;
		--space-9: 20px;
		--space-10: 22px;
	}
}

@media(min-width: 2000px) {
	:root {
		
		--space-0: 0;
		--space-1: 6px;
		--space-2: 8px;
		--space-3: 10px;
		--space-4: 12px;
		--space-5: 14px;
		--space-6: 16px;
		--space-7: 18px;
		--space-8: 20px;
		--space-9: 22px;
		--space-10: 24px;
	}
}