@import './Breakpoints/breakpoint.css';
@import './Colors/color.css';
@import './ZIndex/z-index.css';
@import './Font/font.css';
@import './Space/space.css';
@import './Components/button.css';
@import './Components/input.css';

html, body, #root {
	width: 100vw; height: 100vh;
	margin: 0; padding: 0;

	background-color: var(--color-layout-0);
	color: var(--color-layout-F);
}

*, *:before, *:after {
	box-sizing: border-box;
}

/* SCROLLBAR */
::-webkit-scrollbar {
	width: 8px;
	height: 8px;
}
::-webkit-scrollbar-button {
	width: 0px;
	height: 0px;
}
::-webkit-scrollbar-thumb {
	background: var(--color-layout-E);
	border: 0px none var(--color-layout-F);
	border-radius: 50px;
}
::-webkit-scrollbar-thumb:hover {
	background: var(--color-layout-F);
}
::-webkit-scrollbar-thumb:active {
	background: var(--color-layout-F);
}
::-webkit-scrollbar-track {
	background: var(--color-layout-6);
	border: 0px none var(--color-layout-F);
	border-radius: 46px;
}
::-webkit-scrollbar-track:hover {
	background: var(--color-layout-6);
}
::-webkit-scrollbar-track:active {
	background: var(--color-layout-6);
}
::-webkit-scrollbar-corner {
	background: transparent;
}

/* CANVAS TESTING */
.canvas-auto-test {
	max-width: 0;
	max-height: 0;
}

img {
	max-width:100%;
}

.icon {
	margin: 4px 12px 0 6px;
    min-width: 14px;
    min-height: 14px;
    width: 16px;
    height: 16px;
    width: 1.5vmax;
    height: 1.3vmax;
}

#root > .data-display.ERROR > .dd-it-icon,
#root > .data-display.ERROR_RETRY > .dd-it-icon,
#root > .data-display.ERROR_LOGOUT > .dd-it-icon,
#root > .data-display.ERROR_UNKNOWN > .dd-it-icon,
#root > .data-display.EMPTY > .dd-it-icon {
	width: 36px; height: 36px;
	width: var(--font-10); height: var(--font-10);
}

#adalIdTokenFrame {top: 0;}